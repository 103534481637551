.sim_layout_background {
    background-image: url('/public/simfuni_watermark.png');
    background-position: bottom 0 left 0;
    background-repeat: no-repeat;
    background-size: 275px 270px;
    background-blend-mode: luminosity;
    background-attachment: fixed;
}

.sim_layout_content {
    min-height: 100%;
}

.sim_layout_body {
    min-height: calc(100vh - 112px);
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 20px 20px 30px;
}

.simfumi_layout_header {
    background: #fff;
    box-shadow:
        0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 0px 0px 24px 24px;
    padding: 10px;
}

.sim_layout_footer {
    width: 100%;
    height: 50px;
    padding: 10px;
    text-align: center;
    font-size: 12px;
}

.sim_text_right {
    text-align: right;
}

.sim_repayment_box_outer {
    display: flex;
    width: 100%;
}

.sim_repayment_box_inner {
    margin: 0 auto 15px;
}

.sim_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: 90%;
    background: #fff;
    border: 1px solid #fff;
    box-shadow:
        0px 20px 24px -4px rgba(16, 24, 40, 0.08),
        0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 16px;
    padding: 5px 20px 20px;
}
