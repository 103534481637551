:root {
    --cc-input-border-color: '#008BCE';
}

body {
    min-height: 100% !important;
    margin: 0;
    color: #667085;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'Montserrat', Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

body.with-background {
    background: #f2f4f7;
}

.flex-microform {
    height: 36px;
    background: #ffffff;
    transition: background 200ms;
    padding: 0 15px;
    border: #e0e0e0 1px solid;
    color: #9e9e9e;
    border-radius: 8px;
}

.flex-microform:hover {
    border: rgba(0, 0, 0, 0.87) 1px solid;
}

.registered-error > .flex-microform,
.registered-error > .flex-microform-focused:hover {
    border-color: #d32f2f;
}

.flex-microform-focused,
.flex-microform-focused:hover {
    border-width: 2px;
    border-color: var(--cc-input-border-color);
    padding: 0 13px;
}

.loan-contract > .new-page {
    margin: 20px 0 0 0;
    border-top: 1px dotted #bdbdbd;
}

.payment-method-accordion.MuiPaper-root,
.payment-method-accordion .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters,
.payment-method-accordion .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;
}

.portfolio-detail.MuiAccordionDetails-root {
    background: white;
}
